<template>
  <section class="homepage-videos__wrapper">
    <div class="homepage-videos__layout-wrapper">
      <div class="homepage-videos__content-wrapper">
        <div class="homepage-videos__main-video-wrapper">
          <a-video
            :video="mainVideo.Video"
            :aspect-ratio="$options.consts.aspectRatios.homepageTopVideo"
            :rounded="true"
          />
          <a-dots-responsive
            :text="getVideoTitle(mainVideo)"
            tag="h4"
            :settings="$options.consts.mainVideoTitleSettings"
            class="homepage-videos__main-video-title"
          />
          <a-dots-responsive
            :text="getVideoDescription(mainVideo)"
            tag="h4"
            :settings="$options.consts.mainVideoDescriptionSettings"
            class="homepage-videos__other-video-title"
          />
        </div>
        <div class="homepage-videos__other-videos">
          <ul class="homepage-videos__other-videos-wrapper">
            <li
              v-for="video in otherVideos"
              :key="video.Id"
              class="homepage-videos__other-video-wrapper"
            >
              <a-video
                :video="video.Video"
                :aspect-ratio="$options.consts.aspectRatios.homepageTopVideo"
                :rounded="true"
              />
              <a-dots-responsive
                :text="getVideoTitle(video)"
                tag="h4"
                :settings="$options.consts.otherVideoTitleSettings"
                class="homepage-videos__other-video-title"
              />
              <a-dots-responsive
                :text="getVideoDescription(video)"
                tag="h4"
                :settings="$options.consts.otherVideoDescriptionSettings"
                class="homepage-videos__other-video-description"
              />
            </li>
          </ul>
          <a-visibility hide :on="[$breakpoint.mobile]">
            <a-button
              :href="$options.consts.FM_YOUTUBE_URL"
              class="homepage-videos__see-all-videos"
              >See all videos</a-button
            >
          </a-visibility>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import AVideo from 'shared/AVideo'
import { aspectRatios } from 'enums/aspectRatios'
import ADotsResponsive, { configADots } from 'shared/ADotsResponsive'
import { FM_YOUTUBE_URL } from 'enums/youtube'
import scss from '@/utils/scss'
const { breakpoints } = scss

export default {
  name: 'HomepageVideos',
  mixins: [serverCacheKey],
  components: { ADotsResponsive, AVideo },
  props: {
    videos: propValidator([PROP_TYPES.ARRAY])
  },
  consts: {
    aspectRatios,
    mainVideoTitleSettings: {
      [breakpoints.aboveDesktopXl]: configADots(1, 22, false),
      [breakpoints.desktopXl]: configADots(2, 22, false),
      [breakpoints.mobile]: configADots(3, 22, false)
    },
    mainVideoDescriptionSettings: {
      [breakpoints.aboveDesktopXl]: configADots(2, 22, false),
      [breakpoints.desktopXl]: configADots(3, 22, false),
      [breakpoints.mobile]: configADots(4, 22, false)
    },
    otherVideoTitleSettings: {
      [breakpoints.aboveDesktopXl]: configADots(2, 22, false),
      [breakpoints.desktopXl]: configADots(3, 22, false),
      [breakpoints.desktopLg]: configADots(3, 18, false),
      [breakpoints.desktopMd]: configADots(4, 18, false),
      [breakpoints.tablet]: configADots(2, 22, false),
      [breakpoints.mobile]: configADots(1, 22, false)
    },
    otherVideoDescriptionSettings: {
      [breakpoints.mobile]: configADots(2, 22, false)
    },
    FM_YOUTUBE_URL
  },
  computed: {
    mainVideo() {
      return this.videos[0]
    },
    otherVideos() {
      return this.videos.slice(1, 5)
    }
  },
  methods: {
    getVideoTitle(video) {
      return video.Title || video.Video.Title
    },
    getVideoDescription(video) {
      return video.Video.Description
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';

.homepage-videos__wrapper {
  background: $c--black;
  color: $c--white;

  @include fullWidthWithoutPadding;

  .homepage-videos__layout-wrapper {
    @include page-layout;
    @include page-paddings;
  }

  .homepage-videos__content-wrapper {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: 55% minmax(0, 100%),
        $tablet: minmax(0, 100%)
      ),
      $columnGapSetup: (
        $above-desktop-xl: 46px,
        $desktop-xl: 35px,
        $desktop-lg: 20px,
        $desktop-md: 15px
      ),
      $rowGapSetup: (
        $tablet: 20px,
        $mobile: 15px
      )
    );

    @include gridBuilder($grid-setup);

    $padding: (
      $above-desktop-xl: 63px 0 43px,
      $desktop-xl: 70px 0 45px,
      $desktop-lg: 35px 0 20px,
      $desktop-md: 27px 0 35px,
      $tablet: 20px 0 40px,
      $mobile: 5px 0 15px
    );
    @include setStyleByBreakpoint('padding', $padding);
  }

  .homepage-videos__other-videos-wrapper {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: repeat(2, minmax(0, 100%)),
        $mobile: minmax(0, 100%)
      ),
      $columnGapSetup: (
        $above-desktop-xl: 40px,
        $desktop-xl: 30px,
        $desktop-lg: 25px,
        $desktop-md: 15px,
        $tablet: 50px
      ),
      $rowGapSetup: (
        $above-desktop-xl: 25px,
        $desktop-md: 15px,
        $tablet: 20px
      )
    );

    @include gridBuilder($grid-setup);
  }

  .homepage-videos__main-video-title {
    $margin-top: (
      $above-desktop-xl: 40px,
      $desktop-xl: 60px,
      $desktop-lg: 75px,
      $desktop-md: 60px,
      $tablet: 15px,
      $mobile: 25px
    );
    $font-size: (
      $above-desktop-xl: 18px,
      $desktop-lg: 16px,
      $tablet: 18px,
      $mobile: 16px
    );

    @include setStyleByBreakpoint('margin-top', $margin-top);
    @include setStyleByBreakpoint('font-size', $font-size);
    font-weight: 700;
  }

  .homepage-videos__other-video-title {
    $margin-top: (
      $above-desktop-xl: 25px,
      $desktop-xl: 15px,
      $desktop-md: 10px,
      $tablet: 15px
    );
    $font-size: (
      $above-desktop-xl: 14px,
      $mobile: 16px
    );

    @include setStyleByBreakpoint('margin-top', $margin-top);
    @include setStyleByBreakpoint('font-size', $font-size);

    @include mobile {
      font-weight: 700;
    }
  }

  .homepage-videos__other-video-description {
    font-size: 16px;
  }

  .homepage-videos__see-all-videos {
    $width: (
      $above-desktop-xl: 243px,
      $desktop-lg: 209px,
      $desktop-md: 168px,
      $tablet: 243px
    );
    $font-size: (
      $above-desktop-xl: 18px,
      $desktop-lg: 17px,
      $desktop-md: 14px,
      $tablet: 18px
    );

    @include setStyleByBreakpoint('width', $width);
    margin: 40px auto 0;
    text-transform: uppercase;
    font-weight: 400;
  }
}
</style>
